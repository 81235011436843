//Vars
$primary:    #007bff;
$secondary:  #6c757d;
$success:    #28a745;
$warning:    #ffc107;
$danger:     #dc3545;
$olive:      #3d9970;
$orange:     #ff851b;
$secondary:  #6c757d;
$teal:       #39cccc;
$info:       #17a2b8;
$indigo:     #6610f2;

/* Fix for tabs *******************************************************************************************************/
.card {
  &.card-outline-tabs {
    border-top: none!important;
  }
  &.card-outline:not(.card-outline-tabs) {
    &>.card-header a:hover {
      border-top-color: transparent;
    }
  }
}

/* Fix for select2 multiple *******************************************************************************************/
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0 .375rem .3rem;
  margin-bottom: -.375rem!important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin-top: .35rem;
}

.select2-dropdown {
  z-index: 1100;
}


/* Navbar */
.main-header {
  .breadcrumb {
    margin: 0;
    padding: 0 1rem;
    background-color: transparent;
    border-radius: 0;
    max-width: calc(100% - 228px);
    flex-wrap: nowrap;
    .breadcrumb-item.nav-item {
      white-space: nowrap;
      &.active {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .navbar-nav.user-block {
    max-width: 182px;
    .nav-link .user-block__username {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 150px;
    }
  }
}

.dropdown-toggle.caret-ml-0 {
  &::after {
    margin-left: 0;
  }
}

/* iCheck *************************************************************************************************************/
[class*=icheck-].icheck-single {
  label {
    &::before {
      margin-left: -26px!important;
    }
    &::after {
      left: 3px!important;
    }
  }
}

/*Borders *************************************************************************************************************/
.border-status {
  border-left-width: 4px !important;
  border-left-style: solid !important;
  &-refused {
    border-left-color: $danger !important;
  }
  &-ok,
  &-success {
    border-left-color: $success !important;
  }
  &-orange {
    border-left-color: $orange !important;
  }
  &-waiting {
    border-left-color: $primary !important;
  }
  &-warning {
    border-left-color: $warning !important;
  }
  &-info {
    border-left-color: $info !important;
  }
  &-secondary {
    border-left-color: $secondary !important;
  }
  &-indigo {
    border-left-color: $indigo !important;
  }
}

/*.content-wrapper {
  &>.content {
    padding-top: 1rem;
  }
}*/

/* Fixed Content Header */
.content-wrapper.fixed-header {
  .content-header {
    position: fixed;
    top: 57px;
    left: 250px;
    right: 0;
    background-color: #f4f6f9;
    z-index: 100;
    transition: left .3s ease-in-out;
  }
  .content {
    padding-top: 72px;
    @media screen and (max-width: 575px) {
      padding-top: 100px;
    }
  }
}

.sidebar-collapse {
  .content-wrapper.fixed-header {
    .content-header {
      left: 4.6rem;
      @media screen and (max-width: 992px) {
        left: 0;
      }
    }
  }
}

/* Bread crumbs */
/*.breadcrumb-item {
  color: #6c757d;
}*/


/* FORMS **************************************************************************************************************/

.help-txt,
.help-block {
  display: block;
  visibility: hidden;
  min-height: 1.5rem;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #ee2701;
}

.form-group {
  margin-bottom: 1.5rem;
  &.required {
   /* margin-bottom: 0;*/
    &>label::after {
      content: "*";
      color: #ee2701;
      margin-left: 2px;
    }
  }
  &.has-error {
    .help-txt {
      visibility: visible;
    }
    .form-control,
    .select2-container--default .select2-selection--single,
    .select2-container--default .select2-selection--multiple,
    .input-group-text {
      border-color: #dd4b39;
      box-shadow: none;
    }
  }
}

.works-group__details {
  display: none;
}


/* Fix for select2 ****************************************************************************************************/
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
   margin-bottom: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  font-size: .95rem;
}
//Hide selected options from the options list
.select2-results__option[aria-selected=true] {
  display: none;
}


/* Tables *************************************************************************************************************/
.td-hover:hover {
  cursor: pointer;
}

/* Hide * *******************************************************************************************************/
.border-right-hide-sm {
  @media screen and (max-width: 991px) {
    border-right: none!important;
  }
}
.border-bottom-show-sm {
  @media screen and (min-width: 992px) {
    border-bottom: none!important;
  }
}

/* Sortable ***********************************************************************************************************/
.sortable-group {
  &>li:hover {
    cursor: move;
  }
}
.num-order {
  display: inline-block;
  min-width: 30px;
}

/* Modal **************************************************************************************************************/
.modal-fixed-header.modal-fixed-bottom {
  overflow: hidden;
  .modal-body {
    max-height: calc(100vh - 140px - 3.5rem);
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.second-modal {
  z-index: 1070!important;
}

div.modal-backdrop + div.modal-backdrop {
  z-index: 1060;
}


/* Card widget ********************************************************************************************************/
.card.card-widget {
  @media screen and (max-width: 575px) {
    .row {
      .border-right {
        border-right: 0!important; //Remove border right
      }
    }
  }
}